import React from 'react';
import { Container } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container align="center" sx={{
      marginY: 2,
      textAlign: "left"
    }}>
      <h1 >Privacy Policy for EvoPluz.com </h1>
      <p>Last Updated: 1/20/2024</p>

      <h2>1. Introduction</h2>
      <p>Welcome to EvoPluz.com. This privacy policy explains how we collect, use, and protect your personal information when you visit our website and use our services.</p>

      <h2>2. Information Collection</h2>
      <p>We collect information in the following ways:</p>
      <ul>
        <li>Information you provide to us directly (e.g., name, email address, phone number).</li>
        <li>Information collected automatically (e.g., IP address, browser type, visit duration).</li>
      </ul>

      <h2>3. Use of Information</h2>
      <p>The information we collect is used for the following purposes:</p>
      <ul>
        <li>To provide and improve our services.</li>
        <li>To communicate with you about updates or offers.</li>
        <li>To comply with legal obligations.</li>
      </ul>

      <h2>4. Data Sharing and Disclosure</h2>
      <p>We do not sell your personal data. We may share your information with third parties in the following scenarios:</p>
      <ul>
        <li>With service providers who assist us in our operations.</li>
        <li>If required by law or to protect our rights.</li>
      </ul>

      <h2>5. Data Security</h2>
      <p>We take reasonable measures to protect your information from unauthorized access, alteration, or destruction.</p>

      <h2>6. Your Rights</h2>
      <p>You have the following rights concerning your personal data:</p>
      <ul>
        <li>The right to access and receive a copy of your data.</li>
        <li>The right to request correction of incorrect data.</li>
        <li>The right to request deletion of your data under certain circumstances.</li>
      </ul>

      <h2>7. Children's Privacy</h2>
      <p>Our website does not knowingly collect information from children under the age of 13.</p>

      <h2>8. Changes to This Privacy Policy</h2>
      <p>We may update this policy from time to time. We encourage you to review it regularly.</p>

      <h2>9. Contact Us</h2>
      <p>If you have questions about this policy, please contact us at sakorada@hotmail.com.</p>
    </Container>
  );
};

export default PrivacyPolicy;
