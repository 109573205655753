import { Container, Typography } from "@mui/material";

const NotFound = () => {
    return ( 
        <Container align={"center"} sx={{
            mt: 2,
            mb: 2,
            pt: 2,
            pb: 2,
            textAlign: "center",
        }}>
            <Typography variant="h1" align="center" color="color.red" gutterBottom>
                404 !!!
            </Typography>
            <Typography variant="h4" align="center" color="#ffffff" gutterBottom>
                Opps! That page cannot be found!
                <br/>
                កំណប់ដែលអ្នកកំពុងស្វែងរក មិនមាននៅទីនេះទេ!
            </Typography>
        </Container>
     );
}
 
export default NotFound;