import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import DomainAlert from './DomainAlert';
import navbarData from './data/navbar.json'; // Adjust the path as needed

const drawerWidth = 200;

function DrawerAppBar(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [navItems, setNavItems] = useState([]);

  useEffect(() => {
    setNavItems(navbarData.navbar);
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState);
  };

  // Use the global window object directly
  const container = window !== undefined ? () => window.document.body : undefined;


  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', backgroundColor: "darkgray" }}>
      <Typography variant="h6" sx={{ my: 2, color: "#222222" }}>
        EvoPluz
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <React.Fragment key={item.title}>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center', color: "#222222" }}>
                <Link to={item.url} style={{ textDecoration: 'none', color: "#000000" }}>
                  <ListItemText primary={item.title} />
                </Link>
              </ListItemButton>
            </ListItem>
            {item.submenu && (
              <List component="div" disablePadding>
                {item.submenu.map(subItem => (
                  <ListItemButton sx={{ pl: 4 }} key={subItem.title}>
                    <Link to={subItem.url} style={{ textDecoration: 'none', color: "#000000" }}>
                      <ListItemText primary={subItem.title} />
                    </Link>
                  </ListItemButton>
                ))}
              </List>
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );


  return (
    <>
      <Box sx={{ display: 'flex', mb: 7 }}>
        <AppBar component="nav">
          <Toolbar sx={{
            backgroundColor: "#335577",
            color: "#ffffff"
          }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'center', alignItems: 'center' }}>
              <img src="/logo512.png" alt="EvoPluz logo" className="logo" />
              <a href="/">
                <Typography variant="h6" color="inherit" noWrap>
                  EvoPluz
                </Typography>
              </a>
            </Box>

            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}
            >
              <img src="/logo512.png" alt="EvoPluz logo" className="logo" />
              <a href="/">EvoPluz</a>
            </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {navItems.map((item) => (
                // item.title === "Products" ? "" :
                <Link to={item.url} style={{ textDecoration: 'none', color: "#ffffff" }} key={item.title}>
                  <Button color="inherit">
                    {item.title}
                  </Button>
                </Link>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: "darkgrey" }
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
      <DomainAlert />
    </>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;
