import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Grid, Container } from "@mui/material";
import { Redirect } from "react-router-dom";
import useStyles from "./Style";
import { dark } from "@mui/material/styles/createPalette";
import useFetch from "./useFetch";
import useContents from "./useContents";
import { useState } from "react";
// import theme from "./Theme";

export default function Products() {
  const jsonFile = "/data/products.json";
    const {
      data: products,
      error,
      isPending,
    } = useFetch(jsonFile);
  // const contents = useContents();
  //   contents && contents.products.map((product) => console.log(product));

  // const products = contents && contents.products;

  const handdleClick = (product) => {
    console.log(product);
    window.location.href = "/products/" + product.name.en ;
  };

  return (
    <Container
      sx={{
        mt: 4,
        mb: 4,
        padding: 4,
        borderRadius: 2,
        textAlign: "center",
        color: "#fff",
        // dark barckground
        backgroundColor: "color.second",
      }}
    >
      <Container sx={{ mt: 4, mb: 2, textAlign: "center" }}>
      <Typography variant="h4" align="center" color="#ffffff" gutterBottom>
        Products
      </Typography>
      <Typography variant="small" align="center" color="#ffffff" gutterBottom >
        Click on the product to see more details and download.
      </Typography>
      </Container>
      

      {/* {isPending && <div>Loading...</div>}
      {error && <div>{error}</div>} */}

      {products && (
        <Grid container spacing={2} justifyContent="center">
          {products.map((product) => (
            <Grid
              item
              key={product}
              xs={12}
              sm={6}
              md={4}
              onClick={() => handdleClick(product)}
            >
              <Card
                sx={{
                  maxWidth: 500,
                  borderRadius: 3,
                  backgroundColor: "transparent",
                  color: "color.seventh",
                  boxShadow: 3,
                  "&:hover": {
                    backgroundColor: "color.blue",
                    color: "color.red",
                    boxShadow: 12 ,
                    borderRadius: 1,
                    
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia component="img" image={product.images.product_image} alt="random" sx={{
                    boxShadow: 6,
                    "&:hover": {
                      boxShadow: 12 ,

                      
                    }
                  }}/>
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {product.name.en}
                    </Typography>
                    <Typography gutterBottom variant="h7" component="div">
                    {product.name.kh}
                    </Typography>

                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
}
