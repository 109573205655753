import { Button, Card, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';

const Donations = () => {
  return (
    <Container
      className="donations"
      align="center"
      sx={{
        mt: 4,
        mb: 4,
        pt: 4,
        pb: 4,
        borderRadius: 2,
        textAlign: "center",
        color: "#fff",
        // dark barckground
        backgroundColor: "color.second",
      }}
    >
      <Typography variant="h4" align="center" color="#ffffff" gutterBottom>
      💝 DONATE 💝
      </Typography>
      <Typography variant="p" align="center" color="#ffffff" gutterBottom>
        We use our own time and money to make these plugins for free to everyone.
        <br />
        If you like our work, please consider donating to help us continue
        making more plugins.
      </Typography>
      <hr />
      
      <Typography variant="h6" align="center" color="#ffffff" gutterBottom sx={
        {
          my: 2,
        }
      }>
        Credit Card
        </Typography>
      <Grid
        container
        spacing={1}
        justifyContent="center"
        className="donation-buttons"
        sx={{
          mt: 2,
        }}
      >
        <Grid item>
            
          <Button
            variant="contained"
            color="primary"
            href="https://checkout.square.site/pay/5eac3ee95d67420f9453e2a58201c786"
          >
            Visa/Master Card
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            href="https://checkout.square.site/pay/5eac3ee95d67420f9453e2a58201c786"
          >
            Apple / Google Pay
          </Button>
        </Grid>
      </Grid>
      <Typography variant="h6" align="center" color="#ffffff" gutterBottom sx={
        {
          my: 2,
        }
      }>
        Paypal
        </Typography>
      <Grid
        container
        spacing={1}
        justifyContent="center"
        className="donation-buttons"
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            href="https://www.paypal.me/evoreturns/5"
          >
            <FavoriteIcon color="error" />
            $5
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            href="https://www.paypal.me/evoreturns/10"
          >
            <FavoriteIcon color="error" />
            $10
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            href="https://www.paypal.me/evoreturns/50"
          >
            <FavoriteIcon color="error" />
            $50
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            href="https://www.paypal.me/evoreturns/100"
          >
            <FavoriteIcon color="error" />
            $100
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            href="https://www.paypal.me/evoreturns/250"
          >
            <FavoriteIcon color="error" />
            $250
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            href="https://www.paypal.com/paypalme/evoreturns"
          >
            <FavoriteIcon color="error" />
            Custom
          </Button>
        </Grid>
      </Grid>
      <Typography variant="h6" align="center" color="#ffffff" gutterBottom sx={
        {
          my: 2,
        }
      }>
        Local Services
        </Typography>
        <Grid
        container
        spacing={1}
        justifyContent="center"
        className="donation-buttons"
        sx={{
          mt: 2,
        }}
      >
                <Grid item >
            <Card sx={{
                  maxWidth: 200,
                  borderRadius: 3,
                  backgroundColor: "color.first",
                  color: "color.seventh",
                  boxShadow: 3,
                  textWrap: "nowrap",
                  fontSize: "1em",
                }}>
                <CardMedia component="img" image="/img/assets/service/aba_logo.png" alt="aba_bank" />
                <CardMedia component="img" image="/img/assets/service/aba_qr.jpg" alt="aba_qr" />
                <CardContent>
                    <Typography gutterBottom variant="small" component="div" noWrap>
                    HING SOPHAL
                    </Typography>
                    <Typography variant="small" color="color.fifth" component="div" >
                    000848674
                    </Typography>
                  </CardContent>
            </Card>
        </Grid>
        
        <Grid item>
          <Card sx={{
                  maxWidth: 200,
                  borderRadius: 3,
                  backgroundColor: "color.first",
                  color: "color.seventh",
                  boxShadow: 3,
                  textWrap: "nowrap",
                  fontSize: "1em",
                }}>
                <CardMedia component="img" image="/img/assets/service/wing_logo.png" alt="wing_qr" />
                <CardContent>
                    <Typography gutterBottom variant="small" component="div" noWrap>
                    NAME:  HING SOPHAL
                    </Typography>
                    <Typography variant="small" color="color.fifth" component="div" >
                    PHONE: 087996766
                    </Typography>
                  </CardContent>
            </Card>
        </Grid>

      </Grid>
      <Container sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h6" align="center" color="#ffffff" gutterBottom>
          Thank you for your support!
        </Typography>
        <Button
            variant="contained"
            color="primary"
            href="/donators"
          >
            <FavoriteIcon color="error" />
            Click here to see the list of donators <FavoriteIcon color="error" />
          </Button>
        </Container>

    </Container>
  );
};

export default Donations;
