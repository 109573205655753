import {
    Grid,
    Container,
    Typography,
    AppBar,
    CssBaseline,
    Toolbar,
    Button,
    createTheme,
    ThemeProvider,
    Alert,

} from "@mui/material";
import { GraphicEq} from "@mui/icons-material";

import Home from "./Home";
import { BrowserRouter as Router , Route , Switch  } from 'react-router-dom';
import ProductDetails from "./ProductDetails";
import Theme from "./Theme";
import "./style.css"
import DomainAlert from "./DomainAlert";
import Test from "./Test";
import NotFound from "./NotFound";
import GetCode from "./GetCode";
import Footer from "./Footer";
import Team from "./About";
import Donations from "./Donations";
import Header from "./Header";
import Products from "./Products";
import Donators from "./Donators";
import PrivacyPolicy from "./PrivacyPolicy";
const theme = Theme;
const App = () => {
    
    return (
        <ThemeProvider theme={theme}>
        <CssBaseline />


        <Router>
            
            {/* <AppBar position="sticky" >
                <Toolbar className="navbar" >
                    <img src="/logo512.png" alt="EvoPluz logo" className="logo" />
                    <a href="/">
                    <Typography variant="h6" color="inherit" noWrap>
                        EvoPluz
                    </Typography>
                    </a>
                </Toolbar>
                <DomainAlert />
            </AppBar> */}
            {/* <Menu /> */}
            <Header />
            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route exact path="/getcode">
                    <GetCode />
                </Route>
                <Route exact path="/about">
                    <Team />
                </Route>
                <Route exact path="/donate">
                    <Donations />
                </Route>
                <Route exact path="/donators">
                    <Donators />
                </Route>
                <Route exact path="/products">
                    <Products />
                </Route>
                <Route exact path="/test">
                    <Test />
                </Route>
                <Route exact path="/privacy-policy">
                    <PrivacyPolicy/>
                </Route>


                <Route exact path="/products/:name">
                    <ProductDetails/>
                </Route>
                <Route  path="*">
                    <NotFound />
                </Route>
               
            </Switch>
        </Router>
        <Footer />
        </ThemeProvider>
    );
};

export default App;
