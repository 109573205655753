import React, { useEffect, useState } from 'react';
import { Alert, Container } from '@mui/material';
const DomainAlert = () => {
    // the alert is displayed by default
    const [alert, setAlert] = useState(true);
            
    useEffect(() => {
        // when the component is mounted, the alert is displayed for 3 seconds
        setTimeout(() => {
            setAlert(false);
        }, 8000);
    }, []);     
        
    return (
        <>
            {alert && <Alert icon={false} variant="filled" severity="warning" sx={{
                fontSize: "1em",
                display: 'block',
                textAlign: "center"
        
        }}>
                យើងកំពុងជួសជុលវេបសាយនេះឡើងវិញ ! <br/>មុខងារជាច្រើននៅមិនទាន់ដំណើរការ 100% នៅឡើយ។
                <br />
                We are currently working on this website ! <br/> Many features are not yet available.
                
            </Alert> 
            }
            
        </>
    )
    
}

export default DomainAlert;