import {
    Grid,
    Container,
    Typography,
    AppBar,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    CssBaseline,
    Toolbar,
    Button,
} from "@mui/material";
import  Products  from "./Products";
import useStyles from "./Style";
import Donations from "./Donations";
import Welcome from "./Welcome";
import Team from "./About";
import Donators from "./Donators";
const Home = () => {
    return ( 
        <>
                
                    {/* <Container maxWidth="sm" sx={{
                    mt: 2,
                    mb: 2,
                }}>
                        <Typography
                            variant="h4"
                            align="center"
                            color="textPrimary"
                            gutterBottom
                        >
                            EvoPluz
                        </Typography>
                        <Typography
                            variant="h5"
                            align="center"
                            // color="textSecondary"
                            paragraph
                        >
                            First KhmerPlugins for Everyone.
                        </Typography>
                        <Container>
                            <Typography
                                variant="small"
                                align="center"
                                color="textSecondary"
                                paragraph
                            >
                                Find us on
                            </Typography>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item>
                                    <a href="https://www.facebook.com/EvoPluz">
                                    <Button variant="contained" color="primary" >
                                        Facebook
                                    </Button>
                                        </a>
                                    
                                </Grid>
                                <Grid item>
                                <a href="https://www.youtube.com/EvoPluz">
                                    <Button variant="contained" color="error">
                                        Youtube
                                    </Button>
                                    </a>
                                </Grid>
                            </Grid>
                        </Container>
                    </Container> */}
                    <Welcome />
                    <Donations />
                    <Products />
                    <Team />
                    <Donators />
            

        </>
     );
}
 
export default Home;