import * as React from "react";
import { CardBody, Card , CardActionArea, Grid, Container , Typography } from "@mui/material";
import useFetch from "./useFetch";



export default function Donators() {
    const fileJson = "/data/donators.json"
    const { data, isPending, error } = useFetch(fileJson);
    // data && console.log(data.donators);
    const donatorsList = () => {
        return (
            <Grid container justifyContent="center" align="center" >
                {data.donators.map((donator) => (
                    <Grid item div key={donator.name}  sx={{
                        borderRadius: 2,
                        boxShadow: "none",
                        margin: 0.5,
                        
                        display: "flex",
                        "&:hover":
                        {
                            color: "color.red",
                            boxShadow: 12 ,
                            cursor: "pointer",
                            zoom: 1.005,
                        },
         
                    
                    }} >
                        <Container sx={{ margin: "0.5" , 
                        backgroundColor:"#334466",
                        borderRadius: 2,
                        boxShadow: 2,
                    
                    }} align="center" >
                        <Typography variant="h8" align="center" gutterBottom>
                            {donator.name}
                        </Typography>
                        <Typography variant="body2" align="center" color="yellow">
                        $ {donator.amount} 
                        </Typography>

                            </Container>

                        
                    </Grid>
                ))}
            </Grid>
        );
    }
  

  return (
    <Container
      sx={{
        my: 2,
        padding: 4,
        borderRadius: 2,
        textAlign: "center",
        color: "#fff",
        // dark barckground
        backgroundColor: "color.second",
      }}
    >
        <Typography variant="h4" align="center" color="#ffffff" gutterBottom>
        Donators
        </Typography>
        <Typography variant="small" align="center" color="#ffffff" gutterBottom>
        We would like to thank all of our donators for their support 💝.<br/>Without them, we would not be able to continue making these plugins.
        </Typography>
        
        <Container sx={{ my:1}}>
            
            {data && donatorsList()}
            </Container>
            <hr />
            <Typography variant="small" align="center" color="#ffffff" gutterBottom>
                  
        (More donators will be added soon....)
        </Typography>


            
            
            {/* <Container sx={{ my:1 , 
                        backgroundColor:"#334466",
                        borderRadius: 2,
                        boxShadow: 2,
            
            }} align="center">
                <Card sx={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    "&:hover":
                        {
                            color: "color.red",
                            boxShadow: 12 ,
                        }
                    
                }} >
                    <CardActionArea>
                            <Typography variant="h6" align="center" gutterBottom>
                                Name 
                            </Typography>
                            <Typography variant="body2" align="center" color="text.secondary">
                                Thank you for your support
                            </Typography>

                       
                    </CardActionArea>
                </Card>
            
            
            </Container> */}




      
    </Container>
  );
}
