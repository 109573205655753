import { Container } from '@mui/material';


const Footer = () => {
    const year = new Date().getFullYear();
    const version = "0.5.0";
    return (  
        <Container align={"center"} sx={{
            marginY: 2
        }}>
            <>EvoPluz.com &copy; {year} v{version}</> <a href="/privacy-policy">Privacy Policy</a> 
        </Container>

    );
}
 
export default Footer;