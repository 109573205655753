import { Box, Container , Typography , Grid , Button} from "@mui/material";
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';

const Welcome = () => {

    //q: how to make the Item style?

    return (  
        <Container align="center" sx={{
            
            backgroundImage: "url('/img/angkor_wat_wallpaper1024x768.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% auto",
            color: "color.light",
            textShadow: "3px 3px 3px black",
            height: "auto",
            padding: "10px",
            
            // full image height on mobile



            // half image height on desktop
            
          }}>
            <img src="/logo.png" alt="EvoPluz logo" className="logo_main" style={{
                maxWidth: "150px",
            
            }}/>
            {/* <Typography variant="h4" align="center"  gutterBottom>
            Evo Pluz
            </Typography> */}
            <Container align="center" sx={{
                
              }}>
            <Typography variant="h5" align="center"  gutterBottom sx={{
            }}>
            ដាក់សំលេងចូលខួរក្បាល
            </Typography>
            <Typography variant="h4" align="center"  gutterBottom sx={{
            }}>
            ទាញអតីតកាលទៅអនាគត
            </Typography>
            </Container>
            <Container>
                            <Typography
                                variant="small"
                                align="center"
                                color="textSecondary"
                                paragraph
                            >
                                Find us on
                            </Typography>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item>
                                    <a href="https://www.facebook.com/EvoPluz">
                                    <Button variant="contained" color="primary" >
                                    <FacebookIcon/> Facebook 
                                    </Button>
                                        </a>
                                    
                                </Grid>
                                <Grid item>
                                <a href="https://www.youtube.com/EvoPluz">
                                    <Button variant="contained" color="error">
                                    <YouTubeIcon/> Youtube 
                                    </Button>
                                    </a>
                                </Grid>
                                <Grid item>
                                <a href="https://t.me/evopluz">
                                    <Button variant="contained" color="info">
                                    <TelegramIcon/>  Telegram 
                                    </Button>
                                    </a>
                                </Grid>
                            </Grid>
                        </Container>
        </Container>
    );
}
 
export default Welcome;