import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Button,
  ButtonBase,
  Container,
  Typography,
  Alert,
} from "@mui/material";

export default function FormPropsTextFields() {
  const queryParameters = new URLSearchParams(window.location.search);
  const _key1 = queryParameters.get("key1");
  const [key1, setKey1] = React.useState(_key1);
  const [key2, setKey2] = React.useState("0000");
  const [alert, setAlert] = React.useState(null);
  const [bgColor, setBgColor] = React.useState("#666666");


  const getKey2 = (key1) => {
    if (key1.length != 4) {
      setAlert("Key1 must be 4 digits | Key1 ត្រូវមាន៤ខ្ទង់");
      return;
    }
    fetch(
      "https://octmhgi0w2.execute-api.us-east-1.amazonaws.com/v1/offlinecode",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        body: JSON.stringify({
          key1: key1,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setKey2(data.key2);
        setBgColor("#222266");
      })
      .catch((error) => {
        console.log("error", error);
        setAlert(error);
      });
  };

  if (_key1 != null) {
    if (_key1.length == 4) {
      getKey2(_key1);
    }
  }

  
  return (
    <Container
      maxWidth={"md"}
      align={"center"}
      sx={{
        backgroundColor: "#555555",
        padding: 2,
        borderRadius: 5,
        mt: 2,
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <Typography
          variant="h5"
          align="center"
          color="#ffffff"
          gutterBottom
          sx={{
            margin: 2,
          }}
        >
          Offline Code Unlock
          <br />
          ដោះកូដ​ពេលគ្មាន​អ៊ីនធឺណិត
        </Typography>
        <hr />
        <Typography
          variant="h6"
          align="center"
          color="#ffffff"
          gutterBottom
          sx={{
            mt: 2,
          }}
        >
          ⬇ Enter KEY 1 ⬇
        </Typography>
        <TextField
          id="filled-number"
          variant="filled"
          label="Key 1"
          type="number"
          defaultValue={key1}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setKey1(e.target.value)}
          sx={{
            mb: 2,
            backgroundColor: "#222222",
          }}
        />
        <Typography variant="h6" align="center" color="#ffffff" gutterBottom>
          ⬇ Copy KEY 2 ⬇
        </Typography>
        <TextField
          id="filled-number"
          variant="filled"
          label="Key 2"
          type="number"
          value={key2}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            mb: 2,
            backgroundColor: bgColor,

          }}
        />
        <br />
        <Button variant="contained" onClick={() => getKey2(key1)}>
          Submit
        </Button>
        <Container sx={{ mt: 2 }}>
        {alert != null ? (
          <Alert severity="error" variant="filled">{alert}</Alert>
        ) : (
          <Alert variant="filled" severity="info">
  Copy key2 Back to KhmerPlugins | ចម្លងឬបញ្ចូល Key2 ទៅក្នុង KhmerPlugins !
</Alert>
        )}
        </Container>
      </div>
    </Container>
  );
}
