import { useHistory , useParams } from "react-router-dom";
import { Grid , Button, Container, Typography } from "@mui/material";
import useFetch from "./useFetch";
import useContents from "./useContents";
import WindowIcon from '@mui/icons-material/Window';
import AppleIcon from '@mui/icons-material/Apple';
import NotFound from "./NotFound";

const ProductDetails = () => {
    const jsonFile = "/data/products.json";
    const { name } = useParams();
    // const { data: product, error, isPending } = useFetch("http://192.168.86.102:3001/products/" + name);
    const { data: products, error, isPending } = useFetch(jsonFile);

    // console.log(product);
    // const contents = useContents();
    // // Function to find a product by ID
    function getProductById(productId) {
    return products.find((product) => product.id === productId);
    }
    // console.log(getProductById(name));
    
    let product ;
    if(products){
        product = getProductById(name);
        console.log(product);
        if (product === undefined) {
            return <NotFound/>;
        }
    }

    return (  
        <div>
            {isPending && <div>Loading...</div>}
            {error && <div>{error}</div>}
            {product && (
                <Container align={"center"} sx={{
                    backgroundColor: "color.second",
                    padding: 4,
                }}>
                    <h2>{product.name.en} | {product.name.kh}</h2>
                    <img src={product.images.instrument_image} alt={product.name.en} width={300}/>
                    
                    <p>{product.content}</p>
                    <Container  maxWidth={"sm"} sx={
                        {
                            margin: 1,
                            border : " 1px solid #ffffff",
                            borderRadius: 2
                        }
                    
                    }>
                        <Typography variant="h6" align="center" color="#ffffff" gutterBottom>
                            Download Links
                        </Typography>
                    <img src={product.images.product_image} alt={product.name.en} width={150} />

                        <Grid container spacing={2} justifyContent="center" alignItems={"center"} >
                                     
                    {product.downloads.map((download) => (
                     
                        <Grid item  sx={{
                            margin: 1
                        }} >
                           
                        <Button endIcon={download.platform === "pc" ? <WindowIcon /> : <AppleIcon/>} variant="contained" color={download.platform === "pc" ? "primary" : "secondary" } href={download.url}
                        sx={{
                            borderRadius: 2,
                            boxShadow: 3,
                            "&:hover": {
                                backgroundColor: "color.blue",
                                color: "color.red",
                                boxShadow: 12 ,
                                border: "solid 3px #0000ff"
                                
                              }
                        
                        }}>
                            {download.name + " v" + download.version } 
                        </Button>
                        </Grid>
                    
                    ))
}
                    
                        </Grid>



                    

                    </Container>
                    
                    

                </Container>
            )}
        </div>
    );
}
 
export default ProductDetails;